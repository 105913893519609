<template>
  <div :class="$style.auth0CallBackSpinner">
    <img src="/static/img/loading.svg" alt="loading" />
  </div>
</template>

<script>
export default {
  name: 'callback',
  props: ['auth'],
  data() {
    this.auth.handleAuthentication()
    return {}
  },
}
</script>

<style module>
.auth0CallBackSpinner {
  position: absolute;
  display: flex;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
</style>
